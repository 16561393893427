export const colors = {
  default900: "#19191B",
  default800: "#27292E",
  default700: "#2F3139",
  default600: "#36373C",
  default500: "#45464C",
  default400: "#555660",
  default300: "#787A85",
  default200: "#A3A4AF",
  default100: "#CDCED7",
  default50: "#EEEEF0",
  primary500: "#16E78F",
  secondary500: "#F12FDE",
  background500: "#181919",
  background400: "#393A3F",
  customCar: "#E1FF27",
  customContainer: "#F638FA",
  customShip: "#0FEAEA",
  red: "#FF3B6A",
};
