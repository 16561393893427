export const ShipList = {
  categories: [
    {
      id: 1,
      name: "Ship_0001",
      rotation: 57.70000000000002,
      supercategory: "",
    },
    { id: 2, name: "Ship_0002", rotation: 330.5, supercategory: "" },
    {
      id: 3,
      name: "Ship_0003",
      rotation: 330.59999999999997,
      supercategory: "",
    },
    {
      id: 4,
      name: "Ship_0004",
      rotation: 333.09999999999997,
      supercategory: "",
    },
    {
      id: 5,
      name: "Ship_0005",
      rotation: 238.89999999999998,
      supercategory: "",
    },
    {
      id: 6,
      name: "Ship_0006",
      rotation: 240.09999999999997,
      supercategory: "",
    },
    { id: 7, name: "Ship_0007", rotation: 248.2, supercategory: "" },
    { id: 8, name: "Ship_0008", rotation: 249.5, supercategory: "" },
    {
      id: 9,
      name: "Ship_0009",
      rotation: 249.09999999999997,
      supercategory: "",
    },
    { id: 10, name: "Ship_0010", rotation: 330.5, supercategory: "" },
    { id: 11, name: "Ship_0011", rotation: 334.8, supercategory: "" },
    { id: 12, name: "Ship_0012", rotation: 241.8, supercategory: "" },
    {
      id: 13,
      name: "Ship_0013",
      rotation: 244.79999999999998,
      supercategory: "",
    },
    { id: 14, name: "Ship_0014", rotation: 240.7, supercategory: "" },
    {
      id: 15,
      name: "Ship_0015",
      rotation: 151.59999999999994,
      supercategory: "",
    },
    { id: 16, name: "Ship_0016", rotation: 331.0, supercategory: "" },
    { id: 17, name: "Ship_0017", rotation: 165.0, supercategory: "" },
    { id: 18, name: "Ship_0018", rotation: 330.5, supercategory: "" },
    {
      id: 19,
      name: "Ship_0019",
      rotation: 150.99999999999997,
      supercategory: "",
    },
    {
      id: 20,
      name: "Ship_0020",
      rotation: 330.19999999999993,
      supercategory: "",
    },
    { id: 21, name: "Ship_0021", rotation: 150.2, supercategory: "" },
    { id: 22, name: "Ship_0022", rotation: 150.5, supercategory: "" },
    {
      id: 23,
      name: "Ship_0023",
      rotation: 150.79999999999998,
      supercategory: "",
    },
    { id: 24, name: "Ship_0024", rotation: 331.3, supercategory: "" },
    { id: 25, name: "Ship_0025", rotation: 241.0, supercategory: "" },
    {
      id: 26,
      name: "Ship_0026",
      rotation: 61.900000000000006,
      supercategory: "",
    },
    {
      id: 27,
      name: "Ship_0027",
      rotation: 239.99999999999997,
      supercategory: "",
    },
    {
      id: 28,
      name: "Ship_0028",
      rotation: 242.29999999999998,
      supercategory: "",
    },
  ],
  list: [
    {
      //원래1
      label: "Ship_0009",
      rotation: 57.70000000000002,
      bbox: [3240.12, 10890.35, 38.91, 155.75],
      corners: [
        [126.82731929237917, 36.963461770792684], // NW
        [126.82787106848369, 36.963461770792684], // NE
        [126.82787106848369, 36.96257540305834], // SE
        [126.82731929237917, 36.96257540305834], // SW
      ],
    },
    {
      //원래2
      label: "Ship_0006",
      rotation: 330.5,
      bbox: [3088.15, 9790.9, 47.07, 158.06],
      corners: [
        [126.83287277345131, 36.96086243602606], // NW
        [126.83359389197722, 36.96086243602606], // NE
        [126.83359389197722, 36.95971745136064], // SE
        [126.83287277345131, 36.95971745136064], // SW
      ],
    },
    {
      //원래3
      label: "Ship_0007",
      rotation: 240.59999999999997,
      bbox: [3153.37, 10807.1, 49.96, 162.17],
      corners: [
        [126.84551135464146, 36.9599380296908], // NW
        [126.84563178684245, 36.9599380296908], // NE
        [126.84563178684245, 36.95980468958911], // SE
        [126.84551135464146, 36.95980468958911], // SW
      ],
    },
    {
      //원래4
      label: "Ship_0008",
      rotation: 243.09999999999997,
      bbox: [3054.1, 10997.75, 40.85, 157.99],
      corners: [
        [126.83977681563451, 36.95720551426572], // NW
        [126.84023077129822, 36.95720551426572], // NE
        [126.84023077129822, 36.95647114149183], // SE
        [126.83977681563451, 36.95647114149183], // SW
      ],
    },
    {
      //원래5
      label: "Ship_0010",
      rotation: 238.89999999999998,
      bbox: [3136.67, 11145.79, 46.8, 158.38],
      corners: [
        [126.84173986051057, 36.956326162033825], // NW
        [126.84229696184848, 36.956326162033825], // NE
        [126.84229696184848, 36.95540776898636], // SE
        [126.84173986051057, 36.95540776898636], // SW
      ],
    },
    {
      //원래6
      label: "Ship_0011",
      rotation: 240.09999999999997,
      bbox: [3162.07, 11184.38, 44.8, 160.61],
      corners: [
        [126.84946718377384, 36.953153674383934], // NW
        [126.849852812597, 36.953153674383934], // NE
        [126.849852812597, 36.95279365155387], // SE
        [126.84946718377384, 36.95279365155387], // SW
      ],
    },
    {
      //원래7
      label: "Ship_0012",
      rotation: 248.2,
      bbox: [3216.22, 11275.45, 29.68, 162.1],
      corners: [
        [126.85000150949821, 36.950839014128896], // NW
        [126.85041081512823, 36.950839014128896], // NE
        [126.85041081512823, 36.950469629703036], // SE
        [126.85000150949821, 36.950469629703036], // SW
      ],
    },
    {
      //원래8
      label: "Ship_0013",
      rotation: 156.5,
      bbox: [2853.39, 1656.32, 59.94, 356.81],
      corners: [
        [126.84918822347156, 36.95040475908302], // NW
        [126.84952289390763, 36.95040475908302], // NE
        [126.84952289390763, 36.950044895696195], // SE
        [126.84918822347156, 36.950044895696195], // SW
      ],
    },
    {
      //원래9
      label: "Ship_0020",
      rotation: 157.09999999999997,
      bbox: [6258.2, 7660.82, 82.16, 548.54],
      corners: [
        [126.85071222333737, 36.950649390564415], // NW
        [126.85103099999998, 36.950649390564415], // NE
        [126.85103099999998, 36.95029462936118], // SE
        [126.85071222333737, 36.95029462936118], // SW
      ],
    },
    {
      //원래10
      label: "Ship_0022",
      rotation: 330.5,
      bbox: [7443.45, 8557.74, 11.86, 45.95],
      corners: [
        [126.84986469196376, 36.95006755941351], // NW
        [126.8502481087669, 36.95006755941351], // NE
        [126.8502481087669, 36.949706807700075], // SE
        [126.84986469196376, 36.949706807700075], // SW
      ],
    },
    {
      //원래11
      label: "Ship_0025",
      rotation: 244.8,
      bbox: [7346.21, 9115.94, 17.41, 83.12],
      corners: [
        [126.8500727856988, 36.94997966063449], // NW
        [126.85043981716846, 36.94997966063449], // NE
        [126.85043981716846, 36.94961382951506], // SE
        [126.8500727856988, 36.94961382951506], // SW
      ],
    },
    {
      //원래12
      label: "Ship_0026",
      rotation: 241.8,
      bbox: [7350.24, 9127.25, 22.59, 105.02],
      corners: [
        [126.85051641860242, 36.949772224982624], // NW
        [126.85075957695109, 36.949772224982624], // NE
        [126.85075957695109, 36.949403], // SE
        [126.85051641860242, 36.949403], // SW
      ],
    },
    {
      //원래13
      label: "Ship_0028",
      rotation: 244.79999999999998,
      bbox: [7518.51, 9011.55, 25.14, 105.33],
      corners: [
        [126.847528471118, 36.971682299671684], // NW
        [126.84801953956202, 36.971682299671684], // NE
        [126.84801953956202, 36.97086957193541], // SE
        [126.847528471118, 36.97086957193541], // SW
      ],
    },
    {
      //원래14
      label: "Ship_0001",
      rotation: 150.7,
      bbox: [384.77, 5265.36, 67.35, 389.14],
      corners: [
        [126.85137034025611, 36.97008408621513], // NW
        [126.85237074679097, 36.97008408621513], // NE
        [126.85237074679097, 36.96814814920415], // SE
        [126.85137034025611, 36.96814814920415], // SW
      ],
    },
    {
      //원래15
      label: "Ship_0002",
      rotation: 151.59999999999994,
      bbox: [1062.63, 6406.54, 88.02, 502.68],
      corners: [
        [126.8559655251004, 36.96756549805946], // NW
        [126.85671507217988, 36.96756549805946], // NE
        [126.85671507217988, 36.96633464182189], // SE
        [126.8559655251004, 36.96633464182189], // SW
      ],
    },
    {
      //원래16
      label: "Ship_0003",
      rotation: 331.0,
      bbox: [2605.3, 6812.38, 14.7, 58.54],
      corners: [
        [126.85975438768476, 36.96571069487871], // NW
        [126.86040037945672, 36.96571069487871], // NE
        [126.86040037945672, 36.96462978361711], // SE
        [126.85975438768476, 36.96462978361711], // SW
      ],
    },
    {
      //원래17
      label: "Ship_0005",
      rotation: 145.0,
      bbox: [2144.95, 8398.09, 68.0, 403.2],
      corners: [
        [126.86582113932776, 36.96266653625732], // NW
        [126.86633039549193, 36.96266653625732], // NE
        [126.86633039549193, 36.961781922398586], // SE
        [126.86582113932776, 36.961781922398586], // SW
      ],
    },
    {
      ///원래18
      label: "Ship_0004",
      rotation: 330.5,
      bbox: [1904.71, 8009.62, 55.41, 324.99],
      corners: [
        [126.86804217127928, 36.96169468417012], // NW
        [126.86886578006603, 36.96169468417012], // NE
        [126.86886578006603, 36.96020972096734], // SE
        [126.86804217127928, 36.96020972096734], // SW
      ],
    },
    {
      //원래19
      label: "Ship_0014",
      rotation: 150.99999999999997,
      bbox: [3320.45, 2357.98, 122.11, 849.93],
      corners: [
        [126.87159533084171, 36.95983202271553], // NW
        [126.87211474591263, 36.95983202271553], // NE
        [126.87211474591263, 36.95891499632439], // SE
        [126.8715953308417, 36.95891499632439], // SW
      ],
    },
    {
      //원래20
      label: "Ship_0015",
      rotation: 330.19999999999993,
      bbox: [3881.34, 3463.71, 91.49, 540.38],
      corners: [
        [126.87543834695317, 36.95800548654301], // NW
        [126.87611145645202, 36.95800548654301], // NE
        [126.87611145645201, 36.956756043779485], // SE
        [126.87543834695317, 36.956756043779485], // SW
      ],
    },
    {
      //원래21
      label: "Ship_0016",
      rotation: 150.2,
      bbox: [4343.81, 4278.02, 78.85, 474.55],
      corners: [
        [126.88061381838222, 36.95640303645187], // NW
        [126.88169025286436, 36.95640303645187], // NE
        [126.88169025286436, 36.95465993464772], // SE
        [126.88061381838222, 36.95465993464772], // SW
      ],
    },
    {
      //원래22
      label: "Ship_0017",
      rotation: 150.5,
      bbox: [5084.32, 5614.49, 62.16, 388.37],
      corners: [
        [126.88514870519934, 36.955962517564515], // NW
        [126.88524587022681, 36.955962517564515], // NE
        [126.88524587022681, 36.955857854467965], // SE
        [126.88514870519934, 36.955857854467965], // SW
      ],
    },
    {
      //원래23
      label: "Ship_0018",
      rotation: 150.79999999999998,
      bbox: [5355.42, 6041.16, 100.53, 651.94],
      corners: [
        [126.88375414946792, 36.9549475474704], // NW
        [126.88412732543765, 36.9549475474704], // NE
        [126.88412732543765, 36.954596089019944], // SE
        [126.88375414946792, 36.954596089019944], // SW
      ],
    },
    {
      //원래24
      label: "Ship_0019",
      rotation: 331.3,
      bbox: [5789.12, 6858.92, 63.4, 402.6],
      corners: [
        [126.88522809214, 36.955182817356], // NW
        [126.8856025789364, 36.955182817356], // NE
        [126.8856025789364, 36.95482379674056], // SE
        [126.88522809214, 36.95482379674057], // SW
      ],
    },
    {
      //원래25
      label: "Ship_0021",
      rotation: 250.0,
      bbox: [6889.92, 8364.34, 131.39, 765.27],
      corners: [
        [126.88435205028615, 36.95469107163423], // NW
        [126.88449468461398, 36.95469107163423], // NE
        [126.88449468461398, 36.95450174417861], // SE
        [126.88435205028615, 36.95450174417861], // SW
      ],
    },
    {
      //원래26
      label: "Ship_0023",
      rotation: 61.900000000000006,
      bbox: [7273.23, 9003.34, 45.55, 154.3],
      corners: [
        [126.88438506673309, 36.95466531016258], // NW
        [126.88457013907461, 36.95466531016258], // NE
        [126.88457013907461, 36.95442609975127], // SE
        [126.88438506673309, 36.95442609975127], // SW
      ],
    },
    {
      //원래27
      label: "Ship_0024",
      rotation: 239.99999999999997,
      bbox: [7453.14, 8900.05, 45.71, 157.62],
      corners: [
        [126.88510979003236, 36.954788172565806], // NW
        [126.88523448242005, 36.954788172565806], // NE
        [126.88523448242005, 36.95463772648251], // SE
        [126.88510979003236, 36.95463772648251], // SW
      ],
    },
    {
      //원래28
      label: "Ship_0027",
      rotation: 242.29999999999998,
      bbox: [7438.7, 9073.31, 15.22, 66.05],
      corners: [
        [126.88576364676437, 36.954928847056415], // NW
        [126.88596961040606, 36.954928847056415], // NE
        [126.88596961040606, 36.95468893053933], // SE
        [126.88576364676437, 36.95468893053933], // SW
      ],
    },
  ],
};
